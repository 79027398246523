<template>
  <header :class="{ 'is-top': isTop }" v-show="$route.path !== '/insto-flexi'">
    <div class="sidebar-wrapper">
      <router-link to="/">
        <img src="../assets/imgs/logo.svg" height="30" alt="logo">
      </router-link>
    </div>
    <div class="tab-wrapper">
      <router-link to="/about-us" class="tab">
      {{$t('headerAbout')}}</router-link>
      <router-link to="/contact-us" class="tab">
      {{$t('headerContact')}}</router-link>
      <div class="custom-select">
        <select class="lan-btns" v-model="language">
          <option v-for="(item, index) in lang" :value="item.value" :key="index">
            {{ $t(`${item.value}`) }}
          </option>
        </select>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
      isTop: false,
      language: this.$i18n.locale,
      lang: [
        {
        value: 'zh'
        }, 
        {
        value: 'en'
        }
      ],
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scroll)
  },
  watch: {
    '$route' () {
      this.toggle = false
    },
    language(val) {
        this.$i18n.locale = val
        console.log(val)
    }
  },
  computed: {
    home(){
      if(this.$route.name == "home") {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    scroll () {
      if (window.pageYOffset > 72) {
        this.isTop = true
      } else {
        this.isTop = false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
header {
  height: 72px;
  width: 100%;
  padding: 0 60px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 998;
  .tab-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 95\0px) {
    height: 60px;
    width: 100%;
    border-right: 0;
    padding: 0 30px;
    img {
      height: 26px;
    }
    .sidebar-wrapper {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 414px) {
    padding: 0 20px;
    img {
      height: 23px;
    }
    .tab {
      margin-left: 12px;
    }
    .lan-btns {
      margin-left: 16px;
    }
  }
  @media screen and (max-width: 390px) {
    .tab {
      display: none;
    }
  }
  .tab {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
    font-size: 14px;
    color: $textBlack;
  }
}

.mobile-sidebar {
  cursor: pointer;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
  .sidebar {
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 50;
    background: $deepBlue;
    margin-bottom: 4px;
    transition: all ease-in-out .5s;
  }
}

.showMenu {
  display: block;
}

.sidebar-modal {
  display: none;
  width: 100%;
  background: $white;
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0 0 20px 0;
  box-shadow: 0 2px 8px 0 $boxShadow;
  z-index: 999;
  transition: all 5s ease-in-out;
  @media screen and (max-width: 900px) {
    display: block;
  }
  a {
    width: 100%;
    padding-left: 30px;
    line-height: 60px;
    height: 60px;
    &:hover {
      background: $mainBlue;
      color: white;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.is-top {
  -webkit-backdrop-filter: blur(20px);
  background: white;
  color: #07abdd;
  box-shadow: 0 5px 5px $lightBlue;
  img {
    opacity: 1;
  }
  .header-wrapper {
    button {
      color: #07abdd;
      font-weight: bold;
    }
  }
}

.lan-btns {
  width: 80px;
  background: #d3ecf3;
  margin-left: 20px;
}

select{
  color: $textBlack;
  font-size: 13px;
  font-family: 'lato';
  padding: 6px 12px;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../assets/imgs/arrow.svg') 90% / 12% no-repeat #d3ecf3 !important;
}

select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}

</style>
